mtl.alloy.factory['stadia-map'] = (function($) {
    'use strict';

    // Mapbox GL JS has a bug in it's handling of RTL, so we have to grab this dependency as well until they
    // combine it with the main library
    if (typeof mapboxgl !== 'undefined') {
        mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.2/mapbox-gl-rtl-text.js');
    }

    /**
     * Controller for rendering a stadia map
     */
    return function(element, options) {
        var intersection = options['intersection'];
        var latitude = parseFloat(options['latitude']);
        var longitude = parseFloat(options['longitude']);
        var markerCoords = [longitude, latitude];

        // Create a new map
        var map = new mapboxgl.Map({
            container: element,
            style: 'https://tiles.stadiamaps.com/styles/alidade_smooth.json',
            center: markerCoords,
            interactive: false
        });

        // Load any required geometry data
        if (intersection === 'disjoint' || intersection === 'intersects') {
            var regionGeoJson = window.regionGeoJson;
        }

        if (intersection === 'intersects' || intersection === 'contains') {
            var radiusGeoJson = options['radiusGeoJson'];
        }

        // Ensure map is loaded
        map.on('load', function() {
            if (intersection === 'disjoint' || intersection === 'intersects') {
                // Add region layer
                addRegionLayer(map, regionGeoJson, 'lincolnshire');

                if (intersection === 'disjoint') {
                    // Get the geographic coordinates of the region polygon
                    var coordinates = regionGeoJson.coordinates[0];
                }

                if (intersection === 'intersects') {
                    // Get the geographic coordinates of the radius circle
                    var coordinates = radiusGeoJson.coordinates[0];
                }
            }

            if (intersection === 'contains' || intersection === 'intersects') {
                // Add radius layer
                addRadiusLayer(map, radiusGeoJson);

                // Get the geographic coordinates of the radius circle
                var coordinates = radiusGeoJson.coordinates[0];
            }

            // Set the map bounds
            var bounds = coordinates.reduce(function(bounds, coord) {
                return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

            // Include the current center
            bounds.extend(markerCoords);

            // Create custom marker element
            var markerElement = createCustomMarker();

            // Set marker location and add to map
            var marker = new mapboxgl.Marker(markerElement)
                .setLngLat(markerCoords)
                .addTo(map);

            // Size the map by bounds
            map.fitBounds(bounds, {
                padding: 30,
                animate: false
            });
        });

        /**
         * Add a region layer to the map
         *
         * @param map  map object
         * @param data geojson data
         * @param id   layer id
         */
        function addRegionLayer(map, data, id) {
            map.addLayer({
                'id': id,
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': data
                },
                'layout': {},
                'paint': {
                    'fill-color': '#b74553',
                    'fill-opacity': 0.5,
                    'fill-outline-color': '#b74553',
                }
            });
        }

        /**
         * Add a radius layer to the map
         *
         * @param map  map object
         * @param data geojson data
         */
        function addRadiusLayer(map, data) {
            // Add fill layer
            map.addLayer({
                'id': 'radius',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': data
                },
                'layout': {},
                'paint': {
                    'fill-color': '#2f6d7f',
                    'fill-opacity': 0.2,
                }
            });

            // Add border shadow layer
            map.addLayer({
                'id': 'radiusBorderShadow',
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': data
                },
                'layout': {},
                'paint': {
                    'line-color': '#2f6d7f',
                    'line-width': 6,
                    'line-opacity': 0.5

                }
            });

            // Add border layer
            // The fill outline width cannot be adjusted - this is the workaround
            map.addLayer({
                'id': 'radiusBorder',
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': data
                },
                'layout': {},
                'paint': {
                    'line-color': '#ffffff',
                    'line-width': 4

                }
            });
        }

        /**
         * Create a custom marker element
         *
         * @returns {HTMLElement}
         */
        function createCustomMarker() {
            var el = document.createElement('div');

            el.className = 'marker';
            el.style.backgroundImage = 'url(/images/map/marker.svg)';
            el.style.width = '40px';
            el.style.height = '40px';

            return el;
        }
    };
}($));
