mtl.alloy.factory['project-results'] = (function($) {
    'use strict';

    /**
     * Controller for loading and displaying project results
     */
    return function(element, options) {
        var $element = $(element);
        var url = options.url;

        // ---------------------------------------------------------

        // Fetch search results
        $.ajax({
            type: "GET",
            url: url,
            timeout: 20000,
            success: function(data) {
                if (data) {
                    var $data = $(data);

                    $element.fadeOut(700, function() {
                        $element.replaceWith(function() {
                            return $data.hide().fadeIn(700);
                        });

                        mtl.alloy.processChildren($data);
                    });

                }
            }
        });
    };

}($));
