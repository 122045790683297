mtl.alloy.factory['flash-modal'] = (function($) {
  'use strict';

  /**
   * Controller for scrolling to page location
   */
  return function(element, options) {

    var $modal = $(element);
    var $modalClose = $modal.find('.modal__close');
    console.log($modalClose);

    // ---------------------------------------------------------

    $modalClose.on('click', function() {
      $modal.remove();
    });
  };

}($));
