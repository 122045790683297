mtl.alloy.factory['offcanvas-menu'] = (function($) {
  'use strict';

  /**
   * Controller for the off-canvas navigation menu
   */
  return function(element, options) {
    var $mobileNav = $('.mobile-nav__inner-wrapper');
    var $element = $(element);
    var $closeButton = $element.parent().find('.layout-header-menu__close');
    var $loginButton = $element.parent().find('.button__login');
    var $menuLinks = $element.parent().find('.layout-header-menu__link');

    var supportedBreakpoints = 'phone phone-l tablet';
    var showClass = 'show-ocm';

    // ---------------------------------------------------------

    // Handle burger menu click event
    $element.on('click', toggleMenu);

    // Handle close button click event
    $closeButton.on('click', hideMenu);

    // Handle menu link click events
    $menuLinks.on('click', hideMenu);

    // Handle login button click events
    $loginButton.on('click', hideMenu);

    /**
     * Show the off-canvas menu.
     */
    function showMenu() {
      if (util.breakpoint.not(supportedBreakpoints)) {
        return;
      }

      $mobileNav.addClass(showClass);
    }

    /**
     * Hide the off-canvas menu.
     */
    function hideMenu() {
      $mobileNav.removeClass(showClass);
    }

    /**
     * Toggle the off-canvas menu.
     */
    function toggleMenu() {
      $mobileNav.hasClass(showClass) ? hideMenu() : showMenu();
    }

    // Hide the menu when leaving supported breakpoints
    util.breakpoint.when(supportedBreakpoints, null, hideMenu);
  };

}($));
