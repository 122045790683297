mtl.alloy.factory['scroll-to'] = (function($) {
    'use strict';

    /**
     * Controller for scrolling to page location
     */
    return function(element, options) {

        var $element = $(element);
        var $internalLinks = $element.find($('a[href*="#"]'));

        // ---------------------------------------------------------

        $internalLinks.on('click', function(e) {
            var href = $(this).attr('href');
            var $destination = $(href.substring(href.indexOf("#")));

            $('html, body').animate({
                scrollTop: $destination.offset().top
            }, 500);

            e.preventDefault();
        });

    };

}($));
