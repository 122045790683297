window.util = window.util || {};
window.util.animationBlocker = (function($) {
	'use strict';

	var BLOCKING_CLASS = 'no-animate';


	/**
	 * Create an animation-blocker manager.
	 */
	function blockerManager(element) {

		var $element = $(element).first();
		element = $element[0];

		var activeBlocks = 0;


		/**
		 * Add an animation blocker.
		 */
		function block() {

			if (++activeBlocks > 0) {
				$element.addClass(BLOCKING_CLASS);
			}

		}


		/**
		 * Release an animation blocker.
		 */
		function unblock() {

			if (--activeBlocks <= 0) {
				$element.removeClass(BLOCKING_CLASS);
			}

		}


		// Public API
		return {
			block: block,
			unblock: unblock
		};

	}


	/**
	 * ...
	 */
	function getBlockerManager(element) {

		var manager = $(element).data('animationBlocker');

		if (manager == null) {
			$(element).data('animationBlocker', (manager = blockerManager(element)));
		}

		return manager;

	}


	/**
	 * Get an animation-blocker instance.
	 */
	return function animationBlocker(element, isBlocked) {

		isBlocked = !!isBlocked;

		var manager = getBlockerManager(element || document.body);
		var unblockTimeoutId = -1;

		element = null;


		/**
		 * Cancel the unblockAfterDelay timeout.
		 */
		function cancelUnblock() {

			if (unblockTimeoutId < 0) {
				return;
			}

			clearTimeout(unblockTimeoutId);
			unblockTimeoutId = -1;

		}


		/**
		 * Block animations.
		 */
		function block() {

			cancelUnblock();

			if (isBlocked) {
				return;
			}

			manager.block();
			isBlocked = true;

		}


		/**
		 * Unblock animations.
		 */
		function unblock() {

			cancelUnblock();

			if (!isBlocked) {
				return;
			}

			manager.unblock();
			isBlocked = false;

		}


		/**
		 * Unblock animations after the given delay.
		 */
		function unblockAfterDelay(_delay) {

			_delay = (_delay != null) ? _delay : 200;

			cancelUnblock();

			unblockTimeoutId = setTimeout(unblock, _delay);

		}


		/**
		 * Block animations for the given delay.
		 */
		function blockForDelay(_delay) {

			block();
			unblockAfterDelay(_delay);

		}


		// Initialise
		if (isBlocked) {
			manager.block();
		}

		// Public API
		return {
			block: block,
			unblock: unblock,
			unblockAfterDelay: unblockAfterDelay,
			blockForDelay: blockForDelay
		};

	};

}(jQuery));
