window.util = window.util || {};
window.util.arrayTweener = (function($) {
	'use strict';

	/**
	 * Get the current time.
	 */
	var now = (function(performance) {

		if (performance == null || performance.now == null) {
			return $.now;
		}

		return performance.now.bind(performance);

	}(window.performance));


	/**
	 * Get a helper that tweens values between two arrays.
	 */
	return function arrayTweener(initialValues, defaultDuration, defaultEasing, subTween) {

		var easing = defaultEasing = defaultEasing || 'swing';
		var duration = defaultDuration = (defaultDuration != null) ? +defaultDuration : 400;
		var durationList = null;

		var finalValues = initialValues;
		var tweenedValues = initialValues;

		var isActive = false;
		var tweenAmount = 1;
		var lastTickTime = 0;

		var self = {};


		/**
		 * Get tweened values for the current time.
		 */
		function getTweenedValues() {

			if (tweenedValues) {
				return tweenedValues;
			}

			var blendFinal = 1;
			var blendInitial = 0;

			if (!durationList) {
				blendFinal = $.easing[easing](tweenAmount, tweenAmount, 0, 1, 1);
				blendInitial = 1.0 - blendFinal;
			}

			if (subTween) {
				initialValues = subTween.getTweenedValues();
				if (!subTween.isActive()) {
					subTween = null;
				}
			}

			var values = [];
			for (var i = 0, ix = Math.max(finalValues.length, initialValues.length); i < ix; i++) {

				if (durationList) {

					var amount = (+(durationList[i]) || 0);
					amount = (amount > 0) ?
						Math.min(duration / amount * tweenAmount, 1) :
						1;

					blendFinal = $.easing[easing](amount, amount, 0, 1, 1);
					blendInitial = 1.0 - blendFinal;

				}

				values.push(
					((+finalValues[i] || 0) * blendFinal) +
					((+initialValues[i] || 0) * blendInitial)
				);

			}

			return (tweenedValues = values);

		}


		/**
		 * Start tweening to the given values.
		 */
		function tweenTo(values, _duration) {

			if (isActive) {
				return arrayTweener(getTweenedValues(), duration, easing, self)
					.tweenTo(values, _duration);
			}

			if ($.isArray(_duration)) {
				duration = Math.max.apply(Math, _duration);
				durationList = _duration;
			}
			else if (_duration != null) {
				duration = +_duration || 0;
				durationList = null;
			}
			else {
				duration = defaultDuration;
				durationList = null;
			}

			tweenedValues = initialValues = getTweenedValues();

			finalValues = values;

			isActive = true;

			tweenAmount = 0.0;
			lastTickTime = now();

			return self;

		}


		/**
		 * Stop tweening and immediatley reset to the given values.
		 */
		function resetTo(values) {

			duration = defaultDuration;
			durationList = null;

			tweenedValues = initialValues = finalValues = values;

			isActive = false;

			tweenAmount = 1.0;
			lastTickTime = now();

			return self;

		}


		/**
		 * Execute a tick.
		 */
		function tick(tickTime) {

			if (tickTime == null) {
				tickTime = now();
			}

			if (subTween) {
				subTween.tick(tickTime);
			}

			var period = Math.max(0.0, (tickTime - lastTickTime) / duration);

			lastTickTime = tickTime;

			tweenAmount += period;
			if (tweenAmount >= 1.0) {
				isActive = false;
				tweenAmount = 1.0;
			}

			tweenedValues = null;

			return tickTime;

		}


		// Return public API
		return $.extend(self, {
			isActive: function() { return isActive; },
			getTweenAmount: function() { return tweenAmount; },
			getTweenedValues: getTweenedValues,
			tweenTo: tweenTo,
			resetTo: resetTo,
			tick: tick,
		});

	};

}(jQuery));
