;(function($, factory) {
  'use strict';

  /**
   * Initialise form validation
   */
  factory['form-validation'] = function(elem, options) {

    var $form = $(elem);
    var $inputs = $form.find('input,textarea,select').filter('[required]');
    var $recaptcha = $form.find("[data-input='recaptcha']");
    var $passwordToggles = $form.find('.form__toggle-password');
    var $submitButton = $form.find('.form__button[type="submit"]:not([formnovalidate])');

    // Find the form's submit button if outside of the form element
    if ($submitButton.length === 0) {
      var formId = $form.attr('id');
      $submitButton = $('.form__button[form="' + formId + '"]');
    }

    // ---------------------------------------------------------
    // INITIALISATION ------------------------------------------
    // ---------------------------------------------------------

    // Initialise form validation on page load
    initValidation();

    /**
     * Initialise form validation
     */
    function initValidation() {
      $inputs.each(function() {
        var $formRow = $(this).closest('.form__row');

        // Copy validation hints and errors to form divs
        if (typeof $(this).attr('data-validation-error') !== 'undefined') {
          $(this).closest('.form__input-wrapper').find('.form__error').html($(this).attr('data-validation-error'));
        }

        if (typeof $(this).attr('data-validation-hint') !== 'undefined') {
          $(this).closest('.form__input-wrapper').find('.form__hint').html($(this).attr('data-validation-hint'));
        }

        // Remove all form validation on page load
        $(this).removeClass('dirty');
        $formRow.removeClass('error');

        // Add required form row decoration
        // if ($(this).prop('required')) {
        //   $formRow.addClass('required');
        // }
      });
    }


    // ---------------------------------------------------------
    // EVENT HANDLERS ------------------------------------------
    // ---------------------------------------------------------

    // Handle field blur events
    $inputs.on('blur', validateField);

    // Handle field input events
    $inputs.on('input', resetField);

    // Handle field focus events
    $inputs.on('focus', focusField);

    // Handle password toggle
    $passwordToggles.on('click', function() {
      $(this).hasClass('show') ? hidePassword($(this)) : showPassword($(this));
    });

    // Handle form submission
    $submitButton.on('click', function(e) {
      var shouldValidate = typeof $(this).attr('formnovalidate') === 'undefined';

      // If valid, submit the form
      if (!shouldValidate || validateForm()) {
        $form.submit();
        $submitButton.prop('disabled', true);
      }

      // Scroll to first error
      if (typeof options.scroll === 'undefined' || options.scroll) {
        scrollToElement($form.find('.error').first());
      }

      e.preventDefault();
    });


    // ---------------------------------------------------------
    // VALIDATION FUNCTIONS ------------------------------------
    // ---------------------------------------------------------

    /**
     * Validate a form field
     * Checks HTML5 validation, confirmation inputs and custom validation
     */
    function validateField() {
      var $field = $(this);
      var fieldIsValid;

      // Mark field as dirty and hide hint
      hideHint($field.addClass('dirty'));

      // Reset HTML5 custom validity
      $field[0].setCustomValidity('');

      // Check field against HTML5 validation
      fieldIsValid = $field[0].checkValidity();

      // Check field against confirmation input
      if (fieldIsValid) {
        fieldIsValid = validateConfirmationField($field);
      }

      // Add custom field validation here
      // e.g. fieldIsValid = someCustomFieldValidation($field);

      // Update form row validation classes
      if (fieldIsValid) {
        $field.closest('.form__row').removeClass('error');
      } else {
        $field.closest('.form__row').addClass('error');
      }
    }

    /**
     * Validate a field against it's confirmation counterpart
     *
     * @param $field form field
     * @returns {boolean}
     */
    function validateConfirmationField($field) {
      var fieldIsValid = true;

      // Check for confirmation attribute
      if (typeof $field.data('matchField') !== 'undefined') {
        // Find confirmation counterpart field
        var $validationField = $form.find('[name="' + $field.data('matchField') + '"]');

        // Check is field values match
        fieldIsValid = $field.val() === $validationField.val();

        // Set custom validity and error message if values do not match
        if (!fieldIsValid) {
          // setCustomValidity() is used to trigger an HTML5 validation error, the message itself is not used
          $field[0].setCustomValidity('Fields must match');

          // Replace standard validation message with match error
          $field.parent().find('.form__error').html($field.data('matchError'));
        }
      }

      return fieldIsValid;
    }

    function validateRecaptcha(formIsValid) {
      var formIsValid = formIsValid;

      // Get the recaptcha elements
      var $recaptchaError = $recaptcha.find('.form__error');

      // Get the recaptcha response
      var recaptchaResponse = $recaptcha.find('[name="g-recaptcha-response"]').val();

      if (recaptchaResponse === "") {
        // Display the recaptcha error
        $recaptcha.addClass('error');
        $recaptchaError.html('Please verify above that you are not a robot');
        $recaptchaError.show();

        formIsValid = false;
      } else {
        // Hide the recaptcha error
        $recaptcha.removeClass('error');
        $recaptchaError.hide();
      }

      return formIsValid;
    }

    /**
     * Determine if the form is valid for submission
     *
     * @returns {boolean}
     */
    function validateForm() {
      var formIsValid = true;

      // Blur all inputs to trigger validation
      $inputs.blur();

      // Check form fields for errors
      $inputs.each(function() {
        if ($(this).closest('.form__row').hasClass('error')) {
          formIsValid = false;
        }
      });

      // Check recaptcha has been completed
      formIsValid = validateRecaptcha(formIsValid);

      return formIsValid;
    }


    // ---------------------------------------------------------
    // HELPER FUNCTIONS ----------------------------------------
    // ---------------------------------------------------------

    /**
     * Hide a form field error
     *
     * @param $field
     */
    function hideError($field) {
      $field.closest('.form__row').removeClass('error');
    }

    /**
     * Show a form field hint
     *
     * @param $field form field
     */
    function showHint($field) {
      $field.parent().find('.form__hint').css('display', 'block');
    }

    /**
     * Remove a form field hint
     *
     * @param $field form field
     */
    function hideHint($field) {
      $field.parent().find('.form__hint').css('display', 'none');
    }

    /**
     * Remove form field validation on reset
     */
    function resetField() {
      hideHint($(this));
      hideError($(this));
    }

    /**
     * Display relevant form field validation on focus
     */
    function focusField() {
      showHint($(this));
      hideError($(this));
    }

    /**
     * Hide the entered password as per default input password type
     *
     * @param $toggle the show/hide password toggle element
     */
    function hidePassword($toggle) {
      $toggle.text('Show password').removeClass('show').addClass('hide');
      $toggle.parent().find('.form__input').attr('type', 'password');
    }

    /**
     * Show the entered password as visible text
     *
     * @param $toggle the show/hide password toggle element
     */
    function showPassword($toggle) {
      $toggle.text('Hide password').removeClass('hide').addClass('show');
      $toggle.parent().find('.form__input').attr('type', 'text');
    }

    /**
     * Scroll to a given element
     *
     * @param $element element to scroll to
     */
    function scrollToElement($element) {
      if (typeof $element.offset() !== 'undefined') {
        $('html, body').stop().animate({
          scrollTop: $element.offset().top
        }, 300);
      }
    }
  };
}(jQuery, mtl.alloy.factory));
