mtl.alloy.factory['scroll-hide'] = (function($) {
  'use strict';

  /**
   * Controller for hiding an element when it scrolls out of view
   */
  return function(element, options) {

    var $element = $(element);
    var $window = $(window);

    var elementIdentifier = options.identifier;
    var elementHeight = $element.outerHeight();
    var elementDistanceFromTop = $element.offset().top + elementHeight;

    // ---------------------------------------------------------

    // Check if an element has disappeared from the top of the page
    function checkElementDistance() {
      if ($window.scrollTop() >= elementDistanceFromTop) {
        document.cookie = "hide-" + elementIdentifier + '=1';
        $element.hide();
      }
    }

    $window.on('scroll', util.debounce(checkElementDistance, 100));

  };

}($));
