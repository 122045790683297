mtl.alloy.factory.rangeWidget = (function($) {
	'use strict';

	/**
	 * Return the range widget factory.
	 */
	return function(element, options) {

		var $element = $(element).first();
		element = $element[0];

		var stepWas = 1;

		var $range = $element.find('input[type="range"]').first();
		var $marker = $element.find('[data-ref="range-marker"]').first().css('display', '');

		var hideMarkerTimeoutID = -1;


		/**
		 * Get the range value.
		 */
		function getValue() {

      return Math.round($range.val()) | 0;

		}


    /**
     * Get the range display value.
     */
		function getDisplayValue() {
      var value = getValue();
      if (value < 1.5) {
        return 1;
      } else if (value < 2.5) {
        return 2;
      } else if (value < 3.5) {
        return 5;
      } else {
        return 10;
      }
		}


		/**
		 * Set the range value.
		 */
		function setValue(val) {

			var was = getValue();

			$range.val(val | 0);

			var now = getValue();
			if (now !== was) {
				$range.trigger('change');
			}

		}


		/**
		 * Start smooth stepping.
		 */
		function startSmoothStep() {

			stepWas = $range.prop('step');

			$range.prop('step', 'any');

		}


		/**
		 * End smooth stepping.
		 */
		function endSmoothStep() {

			var valWas = $range.val();

			$range.prop('step', stepWas);

			var valNow = getValue();
			$range.val(valNow);

			if (valNow !== valWas) {
				$range.trigger('input');
				$range.trigger('change');
			}

		}


		/**
		 * Show the marker.
		 */
		function showMarker() {

			if ($marker[0] == null) {
				return;
			}

			var min = +$range.prop('min') || 0;
			var max = +$range.prop('max') || 0;
			var val = +$range.val() || 0;

			var ratio = (val - min) / (max - min);

			$marker
				.addClass('is-active')
				.attr('data-value', getDisplayValue())
				.css('left', (ratio * 100) + '%')
			;

			// hideMarkerAfter(800);

		}


		/**
		 * Hide the marker.
		 */
		function hideMarker() {

			if ($marker[0] == null) {
				return;
			}

			clearHideMarkerTimeout();

			$marker
				.removeClass('is-active')
			;

		}


		/**
		 * Clear the timeout used to hide the marker.
		 */
		function clearHideMarkerTimeout() {

			if (hideMarkerTimeoutID > -1) {
				clearTimeout(hideMarkerTimeoutID);
				hideMarkerTimeoutID = -1;
			}

		}


		/**
		 * Hide the marker after the given delay.
		 */
		function hideMarkerAfter(delay) {

			clearHideMarkerTimeout();

			hideMarkerTimeoutID = setTimeout(hideMarker, delay);

		}


		/**
		 * On starting user manipulation.
		 */
		function onManipulateStart() {

			$marker.addClass('no-smoothing');

			startSmoothStep();
			showMarker();

		}


		/**
		 * On ending user manipulation.
		 */
		function onManipulateEnd() {

			$marker.removeClass('no-smoothing');

			endSmoothStep();
			showMarker();

		}


		/**
		 * On receiving input.
		 */
		function onInput() {

			showMarker();

		}


		// Bind range input event listeners
		$range
			.on('input change', onInput)
			.on('mousedown touchstart', onManipulateStart)
			.on('mouseup touchend', onManipulateEnd)
		;

		// Initialise state
    showMarker();

	};

}(jQuery));
