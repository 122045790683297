mtl.alloy.factory.menuCollapse = (function($) {
    'use strict';

    /**
     * Controller for the mobile menu collapse
     */
    return function(element, options) {

        var $element = $(element);
        var menuItems = $element.find(options['item']);
        var breakpoints = getBreakpoints(options.breakpoint);

        /**
         * Display the collapsed mobile menu
         */
        function activateMenuCollapse() {
            menuItems.each(function() {
                console.log($(this));
                var $link = $(this).find('a').first();
                if ($(this).hasClass('dropdown')) {
                    $(this).on('click', function(e) {
                        e.preventDefault();
                    });

                    var $subItems = $(this).find('li');
                    $subItems.on('click', function(e) {
                        e.stopPropagation();
                    });

                }

                $(this).find(options['list']).hide();
                $(this).on('click', function() {
                    $(this).toggleClass('active');
                    $link.toggleClass('active');
                    $(this).find(options['list']).slideToggle();
                })
            });
        }

        /**
         * Hide the collapsed mobile menu
         */
        function deactivateMenuCollapse() {
            menuItems.each(function() {
                $(this).unbind();
                $(this).find(options['list']).show();
            });
        }

        /**
         * Get breakpoints below and including a given breakpoint
         */
        function getBreakpoints(maxBreakpoint) {
            switch (maxBreakpoint) {
                case 'phone':
                    return 'phone';
                case 'phone-l':
                    return 'phone phone-l';
                case 'tablet':
                    return 'phone phone-l tablet';
                case 'tablet-l':
                    return 'phone phone-l tablet tablet-l';
                case 'desktop':
                    return 'phone phone-l tablet tablet-l desktop';
                default:
                    return 'phone phone-l tablet';
            }
        }

        util.breakpoint.when(breakpoints, activateMenuCollapse, deactivateMenuCollapse);

    };

}(jQuery));
