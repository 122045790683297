mtl.alloy.factory['table-sort'] = (function($) {
    'use strict';

    /**
     * Controller for handling table sorting
     */
    return function(element, options) {
        var $element = $(element);
        var $links = $element.find('.sortable').find('.table__link');

        // ---------------------------------------------------------

        $links.on('click', function(e) {
            e.preventDefault();

            var $target = $element.find('tbody');
            var $currentActive = $element.find('.active').find('.table__link');
            var $cell = $(this).parent();

            if ($(this)[0] === $currentActive[0]) {
                $cell.toggleClass('asc');
            } else {
                $links.not(this).parent().removeClass('active asc');

                $cell.addClass('active');
            }

            var sortOrder = $cell.hasClass('asc') ? 'ASC' : 'DESC';

            var url = $(this).attr('href') + '&sortOrder=' + sortOrder;

            // Fetch search results
            $.ajax({
                type: "GET",
                url: url,
                timeout: 20000,
                success: function(data) {
                    if (data) {
                        console.log('success');
                        var $data = $(data);

                        $target.fadeOut(300, function() {
                            $target.replaceWith(function() {
                                return $data.hide().fadeIn(300);
                            });
                        });

                        mtl.alloy.processChildren($element);
                    }
                }
            });
        });
    };

}($));
