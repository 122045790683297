mtl.alloy.factory['add-ghosts'] = (function($) {
    'use strict';

    /**
     * Controller for loading and displaying results
     */
    return function(element, options) {

        var $element = $(element);
        var $items = $element.find(options['target']);
        var $ghostItem = $('<div class="' + options["target"].substr(1) + '"></div>');

        // ---------------------------------------------------------

        // Add ghost item if required for flexbox space-between alignment
        var $itemCount = $items.length;
        var $requiresGhost = ($itemCount + 1) % 3 === 0;
        if ($requiresGhost) {
            $element.append($ghostItem);
        }

        while ($itemCount % 3 !== 0) {
            $itemCount++;
        }
    };

}($));

